// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    schedule: {
      root: `${ROOTS.DASHBOARD}/schedule`,
      date: (date) => `${ROOTS.DASHBOARD}/schedule/${date}`,
    },
    reservation: {
      root: `${ROOTS.DASHBOARD}/reservation`,
      details: (confirmationNumber) => `${ROOTS.DASHBOARD}/reservation/${confirmationNumber}`,
      new: `${ROOTS.DASHBOARD}/reservation/new`,
      // yyyy-MM-dd
      newWithDate: (dateStr) => `${ROOTS.DASHBOARD}/reservation/new/${dateStr}`,
      edit: (confirmationNumber) => `${ROOTS.DASHBOARD}/reservation/${confirmationNumber}/edit`,
    },
    gallery: {
      root: `${ROOTS.DASHBOARD}/gallery`,
    },
    task: {
      root: `${ROOTS.DASHBOARD}/task`,
    },
    setting: {
      root: `${ROOTS.DASHBOARD}/setting`,
    },
    device: {
      root: `${ROOTS.DASHBOARD}/device`,
    },
  },
};

import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const PageSchedule = lazy(() => import('src/pages/dashboard/schedule'));
const PageReservation = lazy(() => import('src/pages/dashboard/reservation/list'));
const PageReservationDetails = lazy(() => import('src/pages/dashboard/reservation/details'));
const PageNewReservation = lazy(() => import('src/pages/dashboard/reservation/new'));
const PageEditReservation = lazy(() => import('src/pages/dashboard/reservation/edit'));
const PageTask = lazy(() => import('src/pages/dashboard/task'));
const PageGallery = lazy(() => import('src/pages/dashboard/gallery'));
const PageSetting = lazy(() => import('src/pages/dashboard/setting'));
const DeviceList = lazy(() => import('src/pages/dashboard/device'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'schedule',
        children: [
          { element: <PageSchedule />, index: true },
          { path: ':date', element: <PageSchedule /> },
        ],
      },
      {
        path: 'reservation',
        children: [
          { element: <PageReservation />, index: true },
          {
            path: 'new',
            element: <PageNewReservation />,
            children: [{ path: ':reservationDate', element: <PageNewReservation /> }],
          },
          { path: ':confirmationNumber', element: <PageReservationDetails /> },
          {
            path: ':confirmationNumber',
            children: [{ path: 'edit', element: <PageEditReservation /> }],
          },
        ],
      },
      {
        path: 'task',
        children: [{ element: <PageTask />, index: true }],
      },
      { path: 'gallery', element: <PageGallery />, index: true },
      { path: 'setting', element: <PageSetting />, index: true },
      { path: 'device', element: <DeviceList />, index: true },
    ],
  },
];
